<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import store from "@/state/store";
import {required} from "vuelidate/lib/validators";
const _ = require('lodash')
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "End of Year Review",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    ...authComputed,
  },
  validations: {
    critical_accomplishments: { required },
    employee_strengths: { required },
    growth_areas: { required },
    action_plan: { required },
    additional_supervisor_comment: { required },
    rate_employee: { required },
    supervisor_recommendation: { required },
    work_quantity: { required },
    work_quality: { required },
    job_knowledge: { required },
    organization_work: { required },
    teamwork: { required },
    initiative_creativity: { required },
    communication_skills: { required },
  },
  async mounted() {
    this.performanceId = this.$route.params.performanceId;
    this.getPerformanceAssessmentDetails();
  },
  data() {
    return {
      title: "End of Year Performance Review",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "End of Year Performance Review",
          active: true,
        },
      ],
      texts: [],
      assessStatus: 0,
      goals: [],
      start: "",
      end: "",
      duration: 0,
      submitted: false,
      count: 0,
      assessments: [],
      employee: null,
      supervisor: null,
      start_date: null,
      end_date: null,

      critical_accomplishments:null,
      employee_strength:null,
      growth_areas:null,
      action_plan:null,
      additional_supervisor_comment:null,
      rate_employee:null,
      supervisor_recommendation:null,
      work_quantity:null,
      work_quality:null,
      job_knowledge:null,
      organization_work:null,
      teamwork:null,
      initiative_creativity:null,
      communication_skills:null,

      supervisorResponseStatus:false,
      closePerformance:false,


    };
  },
  methods: {

    async getPerformanceAssessmentDetails(){
      const url = `${this.ROUTES.performanceImprovement}/supervisor-end-of-year-performance/${this.performanceId}`;
      await this.apiGet(url).then((res) => {
        const { data } = res;
        this.performance_dev = data.performance;
        this.performanceId = this.performance_dev.ppm_id;
        this.assessStatus = this.performance_dev.ppm_status;
        switch (parseInt(this.performance_dev.ppm_status)) {
          case 0:
            this.status = 'Pending';
            break;
          case 1:
            this.status = 'Approved';
            break;
          case 2:
            this.status = 'Declined';
            break;
        }
        this.employee = `${this.performance_dev.employee?.emp_first_name } ${ this.performance_dev.employee?.emp_last_name } (${ this.performance_dev.employee?.emp_unique_id })`;
        this.supervisor = `${this.performance_dev.supervisor?.emp_first_name } ${ this.performance_dev.supervisor?.emp_last_name } (${ this.performance_dev.supervisor?.emp_unique_id })`;
        this.start_date = new Date(this.performance_dev?.ppm_start_date).toDateString() || '';
        this.end_date = new Date(this.performance_dev?.ppm_end_date).toDateString() || '';

        const assessments = data.performance.assessment;
        //console.log(data)
        assessments.map(assess=>{
          this.texts.push({
            id: assess.ppa_id,
            goal: assess.ppa_goal,
            measure: assess.ppa_performance_measure,
            achievements:assess.ppa_achievements || '',
          });
        });
        if(Object.keys(data.supervisorResponse).length > 0){
          if(!(_.isNull(data.supervisorResponse)) || !(_.isEmpty(data.supervisorResponse)) || !(_.isUndefined(data.supervisorResponse))){
            this.critical_accomplishments = data.supervisorResponse?.ppsr_critical_accomplishment || '';
            this.employee_strength = data.supervisorResponse?.ppsr_employee_strength || '';
            this.growth_areas = data.supervisorResponse?.ppsr_growth_areas || '';
            this.action_plan = data.supervisorResponse?.ppsr_action_plan || '';
            this.additional_supervisor_comment = data.supervisorResponse?.ppsr_additional_supervisor_comment || '';

            this.supervisorResponseStatus = parseInt(this.performance_dev.ppm_status) == 1 ? true : false;
            this.closePerformance = parseInt(data.supervisorResponse?.ppsr_status) === 0 ? true : false; //enable for editing | disable

          }
        }else{
          this.closePerformance = true;
        }


        if(!(_.isNull(data.overallPerformance)) || !(_.isEmpty(data.overallPerformance)) || !(_.isUndefined(data.overallPerformance))){
          this.rate_employee = data.overallPerformance?.ppop_rate_employee || '';
          this.supervisor_recommendation = data.overallPerformance?.ppop_supervisor_recommendation || '';
        }

        if(!(_.isNull(data.competency)) || !(_.isEmpty(data.competency)) || !(_.isUndefined(data.competency))){
          this.work_quality = data.competency?.ppcoc_work_quality || '';
          this.work_quantity = data.competency?.ppcoc_work_quantity || '';
          this.job_knowledge = data.competency?.ppcoc_job_knowledge || '';
          this.organization_work = data.competency?.ppcoc_organization_work || '';
          this.teamwork = data.competency?.ppcoc_teamwork || '';
          this.initiative_creativity = data.competency?.ppcoc_initiative || '';
          this.communication_skills = data.competency?.ppcoc_communication_skill || '';
        }

      });
    },
    submitEndOfYearPerformanceAssessment() {
      //const employeeID = this.getEmployee.emp_id;
      const url = `${this.ROUTES.performanceImprovement}/submit-supervisor-end-of-year-performance-assessment`;
        const data = {
          critical_accomplishments:this.critical_accomplishments,
          employee_strength:this.employee_strength,
          growth_area:this.growth_areas,
          action_plan:this.action_plan,
          additional_supervisor_comment:this.additional_supervisor_comment,

          rate_employee:this.rate_employee,
          supervisor_recommendation:this.supervisor_recommendation,

          work_quantity:this.work_quantity,
          work_quality:this.work_quality,
          job_knowledge:this.job_knowledge,
          organization_work:this.organization_work,
          teamwork:this.teamwork,
          initiative_creativity:this.initiative_creativity,
          communication_skills:this.communication_skills,

          performanceId:this.performanceId
        }
        this.apiPost(url, data, "Submission Error").then(() => {
          this.apiResponseHandler("Process Complete", "Submitted");
          this.$router.push({
            name: "performance-assessment"
          });
        });

    },


    approvePerformanceAssessment() {
      const url = `${this.ROUTES.performanceImprovement}/approve-supervisor-performance-assessment`;
      const data = {
        performanceId:this.performanceId,
        supervisorId:this.getEmployee.emp_id
      }
      this.apiPost(url, data, "Approving assessment Error").then(() => {
        this.apiResponseHandler("Process Complete", "Goals Added");
        this.$router.push({
          name: "performance-assessment"
        });
      });

    },

    stripTags(content) {
      return content.replace(/<.*?>/g, "");
    },
    test(event) {
      console.log(event.target.value);
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>

<style>
textarea {
  resize: none;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button
        class="btn btn-secondary"
        @click="$router.push({ name: 'performance-assessment' })"
      >
        <i class="mdi mdi-view-list mr-2"></i>
        Performance Assessment
      </b-button>
      &nbsp; &nbsp;
      <b-button
        class="btn btn-success"
        v-if="supervisorResponseStatus"
        @click="$refs['add-ph'].show()"
      >
        <i class="mdi mdi-check ml-3"></i>
        Approve Assessment
      </b-button>

    </div>
    <scale-loader v-if="apiBusy" />


    <div v-else class="row">
      <div
        class="col-lg-12"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="p-3 bg-light mb-4 d-flex justify-content-between">
                  <div class="d-inline mb-0">
                    <h5 class="font-size-14 mb-0">Employee Details</h5>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Employee Name</span>
                  <span>
                {{ employee }}
              </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Supervisor</span>
                  <span> {{ supervisor }} </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Start Date</span>
                  <span>
                {{ start_date }}
              </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>End Date</span>
                  <span>
                {{ end_date }}
              </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Status</span>
                  <span> {{ status }} </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="p-3 bg-light mb-4 d-flex justify-content-between">
                  <div class="d-inline mb-0">
                    <h5 class="font-size-14 mb-0">Supervisor Details</h5>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Employee Name</span>
                  <span>
                {{ employee }}
              </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Supervisor</span>
                  <span> {{ supervisor }} </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Start Date</span>
                  <span>
                {{ start_date }}
              </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>End Date</span>
                  <span>
                {{ end_date }}
              </span>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>Status</span>
                  <span> {{ status }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4">
              <h5 class="font-size-14 mb-0">End of Year Performance Assessment</h5>
            </div>
            <form @submit.prevent="submitEndOfYearPerformanceAssessment">
              <b-table-simple hover responsive bordered outlined>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>S/n</b-th>
                    <b-th>Goal</b-th>
                    <b-th>Performance Measure</b-th>
                    <b-th>Achievements</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(field, index) in texts" :key="index">
                    <b-td style="width: 1%">
                      {{ index + 1 }}
                    </b-td>
                    <b-td style="width: 19%">
                      {{ stripTags(field.goal || '' ) }}
                    </b-td>
                    <b-td style="width: 11%">
                        {{ stripTags(field.measure || '') }}
                    </b-td>
                    <b-td style="width: 11%">
                      {{ stripTags(field.achievements || '') }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <div class="row  mt-4 p-3" id="reviewEmployee" style="background: #EFF3F7;">
                <div class="col-md-6" style="border-right: 1px solid #fff;">
                  <h6 class="mb-3 text-uppercase">Employee Performance Assessment</h6>
                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Identify critical accomplishments</strong></label>
                      <textarea v-if="closePerformance"  style="resize: none;" v-model="critical_accomplishments"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="critical_accomplishments"></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Identify employee
                        strengths</strong></label>
                      <textarea v-if="closePerformance"  style="resize: none;" v-model="employee_strength"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="employee_strength"></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Identify areas for
                        growth/improvement</strong></label>
                      <textarea v-if="closePerformance"  style="resize: none;" v-model="growth_areas"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else  v-text="growth_areas"></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Action Plan for the
                        remainder of the year</strong></label>
                      <textarea v-if="closePerformance" style="resize: none;" v-model="action_plan"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="action_plan"></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Additional Supervisor
                        comments</strong></label>
                      <textarea v-if="closePerformance"  style="resize: none;" v-model="additional_supervisor_comment"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="additional_supervisor_comment"></p>
                    </div>
                  </div>
                  <h6 class="mb-3 text-uppercase" >Overall Performance</h6>
                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>How do you rate the employee’s overall performance</strong></label>
                      <select v-if="closePerformance" v-model="rate_employee" class="form-control">
                        <option disabled selected>--select--</option>
                        <option value="Exceeds expectations">Exceeds expectations</option>
                        <option value="Meets expectations">Meets expectations</option>
                        <option value="Below expectations">Below expectations</option>
                      </select>
                      <p v-else v-text="rate_employee"></p>
                    </div>
                  </div>
                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Supervisor recommendations?</strong></label>
                      <select v-if="closePerformance" v-model="supervisor_recommendation"  class="form-control">
                        <option disabled selected>--select--</option>
                        <option value="Employee has satisfactorily completed probation and employee’s contract to continue.">Employee has satisfactorily completed probation and employee’s contract to continue.</option>
                        <option value="Employee has not met some objectives, and probation period should be extended for a
further period of three months.">Employee has not met some objectives, and probation period should be extended for a
                          further period of three months.</option>
                        <option value="Employee has not meet most objectives, and employee’s contract must be reviewed.
Other (specify)">Employee has not meet most objectives, and employee’s contract must be reviewed.
                          Other (specify)</option>
                      </select>
                      <p v-else v-text="supervisor_recommendation"></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="mb-3 text-uppercase" >Competency Assessment</h6>
                  <div class="row mb-3 ">
                    <div class="form-group col-md-10">
                      <label><strong>Quantity of work:</strong> extent to which
                        employee accomplishes assigned work
                        within specified time period.</label>
                      <textarea v-if="closePerformance" v-model="work_quantity"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="work_quantity"></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Quality of work:</strong> extent to which
                        employee’s work is well executed, accurate
                        and effective.</label>
                      <textarea v-if="closePerformance" v-model="work_quality"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="work_quality"></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Knowledge of Job:</strong> extent to which
                        employee utilizes necessary knowledge and
                        skills to perform the job.</label>
                      <textarea v-if="closePerformance" v-model="job_knowledge"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-text="job_knowledge" v-else></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Organisation of work:</strong> extent to which
                        employee manages time effectively, and
                        prioritizes assignments.</label>
                      <textarea v-if="closePerformance" v-model="organization_work"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-text="organization_work" v-else></p>
                    </div>
                  </div>

                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Team work:</strong> extent to which employee
                        gets along with co-workers, subordinates,
                        supervisors and beneficiaries.</label>
                      <textarea v-if="closePerformance" v-model="teamwork"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                    <p v-else v-text="teamwork"></p>
                    </div>
                  </div>
                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label><strong>Initiative and creativity:</strong> extent to which
                        employee is self-directed, resourceful and
                        creative in meeting job objectives or facing
                        challenges.</label>
                      <textarea v-if="closePerformance" v-model="initiative_creativity"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-text="initiative_creativity" v-else></p>
                    </div>
                  </div>
                  <div class="row mb-3 border-1">
                    <div class="form-group col-md-10">
                      <label ><strong>Communication skills:</strong> extent to which
                        employee listens effectively and
                        encourages open communication.</label>
                      <textarea v-if="closePerformance" v-model="communication_skills"  style="resize: none;"  class="form-control" placeholder="Type here..."></textarea>
                      <p v-else v-text="communication_skills"></p>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row" v-if="closePerformance">
                <div class="col-12">
                  <b-button
                    v-if="!submitting"
                    class="btn btn-success mt-4"
                    type="submit"
                  >
                    {{ supervisorResponseStatus === true ? 'Save changes' : 'Submit' }}
                  </b-button>
                  <b-button
                    v-else
                    disabled
                    class="btn btn-success btn-block mt-4"
                    type="submit"
                  >
                    Submitting...
                  </b-button>

                  <b-button
                    class="btn btn-secondary"
                    v-if="supervisorResponseStatus"
                    @click="$refs['add-ph'].show()"
                  >
                    <i class="mdi mdi-check ml-3"></i>
                    Approve Assessment
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="add-ph"
             title="Add New Public Holiday"
             hide-footer
             centered
             title-class="font-18"
              hide-header  size="sm">
      <div class="p-3">
        This action cannot be undone. Are you sure you want to <code class="text-success">approve</code> this?
        <div class="row mt-3">
          <div class="col-lg-12">
            <b-button
              v-if="!submitting"
              class="btn btn-success btn-block"
              type="button"
              @click="approvePerformanceAssessment"
            >
              Yes, please
            </b-button>
            <b-button
              v-else
              disabled
              class="btn btn-success btn-block"
              type="button"
            >
              Approving...
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

  </Layout>
</template>
